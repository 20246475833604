exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-autores-tsx": () => import("./../../../src/pages/autores.tsx" /* webpackChunkName: "component---src-pages-autores-tsx" */),
  "component---src-pages-buscar-tsx": () => import("./../../../src/pages/buscar.tsx" /* webpackChunkName: "component---src-pages-buscar-tsx" */),
  "component---src-pages-comentario-diario-historico-tsx": () => import("./../../../src/pages/comentario-diario-historico.tsx" /* webpackChunkName: "component---src-pages-comentario-diario-historico-tsx" */),
  "component---src-pages-comentario-diario-tsx": () => import("./../../../src/pages/comentario-diario.tsx" /* webpackChunkName: "component---src-pages-comentario-diario-tsx" */),
  "component---src-pages-diario-internacional-tsx": () => import("./../../../src/pages/diario-internacional.tsx" /* webpackChunkName: "component---src-pages-diario-internacional-tsx" */),
  "component---src-pages-disclosure-tsx": () => import("./../../../src/pages/disclosure.tsx" /* webpackChunkName: "component---src-pages-disclosure-tsx" */),
  "component---src-pages-economico-tsx": () => import("./../../../src/pages/economico.tsx" /* webpackChunkName: "component---src-pages-economico-tsx" */),
  "component---src-pages-estrategia-tsx": () => import("./../../../src/pages/estrategia.tsx" /* webpackChunkName: "component---src-pages-estrategia-tsx" */),
  "component---src-pages-flash-tsx": () => import("./../../../src/pages/flash.tsx" /* webpackChunkName: "component---src-pages-flash-tsx" */),
  "component---src-pages-flash-vector-tsx": () => import("./../../../src/pages/flash-vector.tsx" /* webpackChunkName: "component---src-pages-flash-vector-tsx" */),
  "component---src-pages-flashes-tsx": () => import("./../../../src/pages/flashes.tsx" /* webpackChunkName: "component---src-pages-flashes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internacional-[slug]-tsx": () => import("./../../../src/pages/internacional/[slug].tsx" /* webpackChunkName: "component---src-pages-internacional-[slug]-tsx" */),
  "component---src-pages-internacional-index-tsx": () => import("./../../../src/pages/internacional/index.tsx" /* webpackChunkName: "component---src-pages-internacional-index-tsx" */),
  "component---src-pages-mercados-tsx": () => import("./../../../src/pages/mercados.tsx" /* webpackChunkName: "component---src-pages-mercados-tsx" */),
  "component---src-pages-renta-fija-tsx": () => import("./../../../src/pages/renta-fija.tsx" /* webpackChunkName: "component---src-pages-renta-fija-tsx" */),
  "component---src-pages-renta-variable-tsx": () => import("./../../../src/pages/renta-variable.tsx" /* webpackChunkName: "component---src-pages-renta-variable-tsx" */),
  "component---src-pages-reportes-trimestrales-tsx": () => import("./../../../src/pages/reportes-trimestrales.tsx" /* webpackChunkName: "component---src-pages-reportes-trimestrales-tsx" */),
  "component---src-pages-semanario-bolsasmundo-tsx": () => import("./../../../src/pages/semanario/bolsasmundo.tsx" /* webpackChunkName: "component---src-pages-semanario-bolsasmundo-tsx" */),
  "component---src-pages-semanario-calendariodividendos-tsx": () => import("./../../../src/pages/semanario/calendariodividendos.tsx" /* webpackChunkName: "component---src-pages-semanario-calendariodividendos-tsx" */),
  "component---src-pages-semanario-directorio-tsx": () => import("./../../../src/pages/semanario/directorio.tsx" /* webpackChunkName: "component---src-pages-semanario-directorio-tsx" */),
  "component---src-pages-semanario-disclosure-tsx": () => import("./../../../src/pages/semanario/disclosure.tsx" /* webpackChunkName: "component---src-pages-semanario-disclosure-tsx" */),
  "component---src-pages-semanario-fundamentalessic-tsx": () => import("./../../../src/pages/semanario/fundamentalessic.tsx" /* webpackChunkName: "component---src-pages-semanario-fundamentalessic-tsx" */),
  "component---src-pages-semanario-valuacionopiniones-tsx": () => import("./../../../src/pages/semanario/valuacionopiniones.tsx" /* webpackChunkName: "component---src-pages-semanario-valuacionopiniones-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-tecnico-tsx": () => import("./../../../src/pages/tecnico.tsx" /* webpackChunkName: "component---src-pages-tecnico-tsx" */),
  "component---src-pages-temas-del-momento-tsx": () => import("./../../../src/pages/temas-del-momento.tsx" /* webpackChunkName: "component---src-pages-temas-del-momento-tsx" */),
  "component---src-pages-todos-tsx": () => import("./../../../src/pages/todos.tsx" /* webpackChunkName: "component---src-pages-todos-tsx" */),
  "component---src-pages-vector-basics-tsx": () => import("./../../../src/pages/vector-basics.tsx" /* webpackChunkName: "component---src-pages-vector-basics-tsx" */),
  "component---src-pages-webinar-tsx": () => import("./../../../src/pages/webinar.tsx" /* webpackChunkName: "component---src-pages-webinar-tsx" */),
  "component---src-templates-cartera-modelo-tsx": () => import("./../../../src/templates/cartera-modelo.tsx" /* webpackChunkName: "component---src-templates-cartera-modelo-tsx" */),
  "component---src-templates-cartera-tecnico-tsx": () => import("./../../../src/templates/cartera-tecnico.tsx" /* webpackChunkName: "component---src-templates-cartera-tecnico-tsx" */),
  "component---src-templates-comentario-semanal-tsx": () => import("./../../../src/templates/comentario-semanal.tsx" /* webpackChunkName: "component---src-templates-comentario-semanal-tsx" */),
  "component---src-templates-diario-economico-tsx": () => import("./../../../src/templates/diario-economico.tsx" /* webpackChunkName: "component---src-templates-diario-economico-tsx" */),
  "component---src-templates-diario-internacional-tsx": () => import("./../../../src/templates/diario-internacional.tsx" /* webpackChunkName: "component---src-templates-diario-internacional-tsx" */),
  "component---src-templates-estrategia-inversion-tsx": () => import("./../../../src/templates/estrategia-inversion.tsx" /* webpackChunkName: "component---src-templates-estrategia-inversion-tsx" */),
  "component---src-templates-expectativas-financieras-anuales-tsx": () => import("./../../../src/templates/expectativas-financieras-anuales.tsx" /* webpackChunkName: "component---src-templates-expectativas-financieras-anuales-tsx" */),
  "component---src-templates-expectativas-reporte-trimestral-tsx": () => import("./../../../src/templates/expectativas-reporte-trimestral.tsx" /* webpackChunkName: "component---src-templates-expectativas-reporte-trimestral-tsx" */),
  "component---src-templates-flash-economico-tsx": () => import("./../../../src/templates/flash-economico.tsx" /* webpackChunkName: "component---src-templates-flash-economico-tsx" */),
  "component---src-templates-flash-emisora-tsx": () => import("./../../../src/templates/flash-emisora.tsx" /* webpackChunkName: "component---src-templates-flash-emisora-tsx" */),
  "component---src-templates-flash-internacional-tsx": () => import("./../../../src/templates/flash-internacional.tsx" /* webpackChunkName: "component---src-templates-flash-internacional-tsx" */),
  "component---src-templates-flash-renta-fija-tsx": () => import("./../../../src/templates/flash-renta-fija.tsx" /* webpackChunkName: "component---src-templates-flash-renta-fija-tsx" */),
  "component---src-templates-flash-tecnico-tsx": () => import("./../../../src/templates/flash-tecnico.tsx" /* webpackChunkName: "component---src-templates-flash-tecnico-tsx" */),
  "component---src-templates-generico-tsx": () => import("./../../../src/templates/generico.tsx" /* webpackChunkName: "component---src-templates-generico-tsx" */),
  "component---src-templates-inicio-de-cobertura-tsx": () => import("./../../../src/templates/inicio-de-cobertura.tsx" /* webpackChunkName: "component---src-templates-inicio-de-cobertura-tsx" */),
  "component---src-templates-mercados-a-la-apertura-tsx": () => import("./../../../src/templates/mercados-a-la-apertura.tsx" /* webpackChunkName: "component---src-templates-mercados-a-la-apertura-tsx" */),
  "component---src-templates-presentacion-analisis-tsx": () => import("./../../../src/templates/presentacion-analisis.tsx" /* webpackChunkName: "component---src-templates-presentacion-analisis-tsx" */),
  "component---src-templates-pulso-tecnico-tsx": () => import("./../../../src/templates/pulso-tecnico.tsx" /* webpackChunkName: "component---src-templates-pulso-tecnico-tsx" */),
  "component---src-templates-renta-fija-tsx": () => import("./../../../src/templates/renta-fija.tsx" /* webpackChunkName: "component---src-templates-renta-fija-tsx" */),
  "component---src-templates-reporte-trimestral-tsx": () => import("./../../../src/templates/reporte-trimestral.tsx" /* webpackChunkName: "component---src-templates-reporte-trimestral-tsx" */),
  "component---src-templates-semanario-financiero-tsx": () => import("./../../../src/templates/semanario-financiero.tsx" /* webpackChunkName: "component---src-templates-semanario-financiero-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-vector-basics-tsx": () => import("./../../../src/templates/vector-basics.tsx" /* webpackChunkName: "component---src-templates-vector-basics-tsx" */),
  "component---src-templates-webinar-tsx": () => import("./../../../src/templates/webinar.tsx" /* webpackChunkName: "component---src-templates-webinar-tsx" */)
}

